import { MouseEvent, ReactNode } from 'react';

import { Button } from '@admin/atoms/Button';

import styles from './InlineButton.module.scss';

interface Props {
    children: ReactNode;
    className?: string;
    onClick?: (e: MouseEvent) => void;
}

export const InlineButton = ({ className, children, onClick }: Props) => {
    if (!onClick) return null;

    return (
        <Button className={`${styles.InlineButton} ${className}`} onClick={onClick}>
            {children}
        </Button>
    );
};
