import { useState } from 'react';
import useTranslation from 'next-translate/useTranslation';

import { Button, ButtonType } from '@admin/atoms/Button';
import { CustomNodeTypes } from '@admin/molecules/RichEditor/Editor/plugins/CustomPluginNode';
import { IframeNodeData } from '@admin/molecules/RichEditor/Editor/plugins/IframePlugin/IframeNode';
import {
    BaseModal,
    BaseModalFooter,
    BaseModalHeader,
    ModalActions,
    useModalContext,
} from '@admin/organisms/BaseModal';
import { Form } from '@admin/organisms/Form';
import FlashMessageUtil from '@admin/utils/FlashMessageUtil/FlashMessageUtil';
import { TranslationKey } from '@admin/utils/TranslationKey';
import { Icon } from '@common/atoms/Icon';
import { sanitizeHtml } from '@web/utils/sanitizeHtml';

import { formFields } from './helpers';

import styles from './EmbedIframeModal.module.scss';

interface Props {
    formData?: IframeNodeData;
    onSubmit: (data: IframeNodeData) => void;
    action?: ModalActions;
}

export const EmbedIframeModal = ({ formData, onSubmit, action = ModalActions.create }: Props) => {
    const __translate = useTranslation(TranslationKey.richEditor).t;

    const { closeModal } = useModalContext(CustomNodeTypes.iframe);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const flashes = FlashMessageUtil(__translate);

    const handleSubmit = async (data: IframeNodeData) => {
        if (!data.iframe) {
            setIsSubmitting(false);
            flashes.customError('insertIframe.missingIframe');
            return;
        }

        const nodeData: IframeNodeData = {
            iframe: sanitizeHtml(data.iframe),
            type: CustomNodeTypes.iframe,
        };

        onSubmit(nodeData);
        setIsSubmitting(false);
        closeModal();
    };

    const clickButton = () => {
        setIsSubmitting(true);
        doSubmit();
    };

    let doSubmit: () => void;
    const bindDoSubmit = (callback: () => void) => {
        doSubmit = callback;
    };

    return (
        <BaseModal className={styles.EmbedIframeModal} contextKey={CustomNodeTypes.iframe}>
            <BaseModalHeader contextKey={CustomNodeTypes.iframe}>
                <div className={styles.title}>
                    <Icon.iframe
                        svgProps={{
                            width: 29,
                            height: 21,
                            viewBox: '0 0 29 21',
                        }}
                    />
                    {__translate('insertIframe.title')}
                </div>
            </BaseModalHeader>
            <p>
                <b>{__translate('insertIframe.label')}</b>
            </p>
            <Form<IframeNodeData, IframeNodeData>
                id={'insertIframe'}
                action={`iframe-${action}`}
                handleSubmit={handleSubmit}
                bindDoSubmit={bindDoSubmit}
                formFields={formFields}
                entity={formData}
            />
            <BaseModalFooter>
                <Button filled={true} loading={isSubmitting} type={ButtonType.submit} onClick={clickButton}>
                    {__translate(`insertIframe.${action}`)}
                </Button>
            </BaseModalFooter>
        </BaseModal>
    );
};
