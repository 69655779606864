import type { EditorConfig, ElementFormatType, LexicalEditor, NodeKey } from 'lexical';

import { CustomNode, CustomNodeTypes } from '@admin/molecules/RichEditor/Editor/plugins/CustomPluginNode';
import { BaseModalProvider } from '@admin/organisms/BaseModal';

import { MatchBlockArticleBlock } from '.';

export interface MatchBlockNodeData {
    matchID: string;
    type: CustomNodeTypes.matchBlock;
}

export class MatchBlockNode extends CustomNode<MatchBlockNodeData, MatchBlockNode>(
    (data: MatchBlockNodeData): MatchBlockNode => new MatchBlockNode(data),
) {
    static override __type = CustomNodeTypes.matchBlock;

    constructor(data: MatchBlockNodeData, format?: ElementFormatType, key?: NodeKey) {
        super(data, format, key);
    }

    static override clone(node: MatchBlockNode): MatchBlockNode {
        return new MatchBlockNode(node.__data, node.__format, node.__key);
    }

    handleOnChange(editor: LexicalEditor) {
        return (data: MatchBlockNodeData) => {
            editor.update(() => {
                const writable: MatchBlockNode = this.getWritable();

                writable.__data.matchID = data.matchID;
            });
        };
    }

    override decorate(editor: LexicalEditor, _config: EditorConfig) {
        return (
            <BaseModalProvider>
                <MatchBlockArticleBlock
                    {...this.__data}
                    onChange={this.handleOnChange(editor)}
                    onDelete={this.handleOnDelete(editor)}
                />
            </BaseModalProvider>
        );
    }
}
