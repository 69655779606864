import { $getSelection, KEY_BACKSPACE_COMMAND } from 'lexical';

import { useCommand } from '@admin/molecules/RichEditor/Editor/hooks';
import { isCustomNode } from '@admin/molecules/RichEditor/Editor/plugins/CustomPluginNode/helpers';

export const RemoveOnBackspacePlugin = () => {
    useCommand(KEY_BACKSPACE_COMMAND, () => {
        const selection = $getSelection();
        const nodes = selection?.getNodes();
        const node = (nodes || [])[0];

        if (isCustomNode(node)) {
            node.remove();
        }

        return false;
    });

    return null;
};
