import type { EditorConfig, ElementFormatType, LexicalEditor, NodeKey } from 'lexical';

import { CustomNode, CustomNodeTypes } from '@admin/molecules/RichEditor/Editor/plugins/CustomPluginNode';
import { BaseModalProvider } from '@admin/organisms/BaseModal';

import { JobsOverviewArticleBlock } from '.';

export interface JobsOverviewNodeData {
    type: CustomNodeTypes.jobsOverview;
}

export class JobsOverviewNode extends CustomNode<JobsOverviewNodeData, JobsOverviewNode>(
    (data: JobsOverviewNodeData): JobsOverviewNode => new JobsOverviewNode(data),
) {
    static override __type = CustomNodeTypes.jobsOverview;

    constructor(data: JobsOverviewNodeData, format?: ElementFormatType, key?: NodeKey) {
        super(data, format, key);
    }

    static override clone(node: JobsOverviewNode): JobsOverviewNode {
        return new JobsOverviewNode(node.__data, node.__format, node.__key);
    }

    override decorate(editor: LexicalEditor, _config: EditorConfig) {
        return (
            <BaseModalProvider>
                <JobsOverviewArticleBlock {...this.__data} onDelete={this.handleOnDelete(editor)} />
            </BaseModalProvider>
        );
    }
}
