import type { EditorConfig, ElementFormatType, LexicalEditor, NodeKey } from 'lexical';

import { CustomNode, CustomNodeTypes } from '@admin/molecules/RichEditor/Editor/plugins/CustomPluginNode';
import { XPost } from '@common/atoms/XPost';

export interface PostNodeData {
    postID?: string;
    type: CustomNodeTypes.post;
}

export class PostNode extends CustomNode<PostNodeData, PostNode>(
    (data: PostNodeData): PostNode => new PostNode(data),
) {
    static override __type = CustomNodeTypes.post;

    constructor(data: PostNodeData, format?: ElementFormatType, key?: NodeKey) {
        super(data, format, key);
    }

    static override clone(node: PostNode): PostNode {
        return new PostNode(node.__data, node.__format, node.__key);
    }

    override decorate(_editor: LexicalEditor, _config: EditorConfig) {
        return <XPost data={{ postID: this.__data.postID }} />;
    }
}
