import { useEffect } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
    $createParagraphNode,
    $insertNodes,
    COMMAND_PRIORITY_EDITOR,
    createCommand,
    LexicalCommand,
} from 'lexical';

import { useCommand } from '@admin/molecules/RichEditor/Editor/hooks';
import { CustomNodeTypes } from '@admin/molecules/RichEditor/Editor/plugins/CustomPluginNode';
import {
    IframeNode,
    IframeNodeData,
} from '@admin/molecules/RichEditor/Editor/plugins/IframePlugin/IframeNode';

export const INSERT_IFRAME_COMMAND: LexicalCommand<string> = createCommand();

export const IframePlugin = (): null => {
    const [editor] = useLexicalComposerContext();

    useEffect(() => {
        if (!editor.hasNodes([IframeNode])) {
            throw new Error('IframePlugin: IframeNode not registered on editor');
        }
    }, [editor]);

    useCommand(
        INSERT_IFRAME_COMMAND,
        (data: IframeNodeData) => {
            const iframeNode = IframeNode.__createNode({ ...data, type: CustomNodeTypes.iframe });
            const paragraph = $createParagraphNode();

            $insertNodes([iframeNode, paragraph]);
            return true;
        },
        COMMAND_PRIORITY_EDITOR,
    );

    return null;
};
