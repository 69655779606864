import { useState } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { LexicalCommand } from 'lexical';
import useTranslation from 'next-translate/useTranslation';

import { Button, ButtonType } from '@admin/atoms/Button';
import { CustomNodeTypes } from '@admin/molecules/RichEditor/Editor/plugins/CustomPluginNode';
import { PostNodeData } from '@admin/molecules/RichEditor/Editor/plugins/XPlugin/PostNode';
import { BaseModal, BaseModalFooter, BaseModalHeader, useModalContext } from '@admin/organisms/BaseModal';
import { Form } from '@admin/organisms/Form';
import FlashMessageUtil from '@admin/utils/FlashMessageUtil/FlashMessageUtil';
import { TranslationKey } from '@admin/utils/TranslationKey';
import { Logo } from '@common/atoms/Logo';

import { formFields } from './helpers';
import { INSERT_POST_COMMAND } from '.';

import styles from './EmbedPostModal.module.scss';

interface FormEntity {
    postID?: string;
}

interface Props {
    formData?: FormEntity;
}

export const EmbedPostModal = ({ formData }: Props) => {
    const __translate = useTranslation(TranslationKey.richEditor).t;

    const { closeModal } = useModalContext(CustomNodeTypes.post);
    const [editor] = useLexicalComposerContext();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const flashes = FlashMessageUtil(__translate);

    const handleSubmit = async (data: FormEntity) => {
        const postID = data?.postID?.split('/')?.pop()?.split('?')?.shift();

        if (!postID) {
            setIsSubmitting(false);
            flashes.customError('insertPost.missingPostID');
            return;
        }

        editor.dispatchCommand(INSERT_POST_COMMAND as LexicalCommand<PostNodeData>, {
            postID,
            type: CustomNodeTypes.post,
        });

        setIsSubmitting(false);
        closeModal();
    };

    const clickButton = () => {
        setIsSubmitting(true);
        doSubmit();
    };

    let doSubmit: () => void;
    const bindDoSubmit = (callback: () => void) => {
        doSubmit = callback;
    };

    return (
        <BaseModal className={styles.EmbedPostModal} contextKey={CustomNodeTypes.post}>
            <BaseModalHeader contextKey={CustomNodeTypes.post}>
                <div className={styles.title}>
                    <Logo.x /> {__translate('insertPost.title')}
                </div>
            </BaseModalHeader>
            <p>
                <b>{__translate('insertPost.label')}</b>
            </p>
            <Form<FormEntity, FormEntity>
                id={'insertPost'}
                action={'insertPost'}
                handleSubmit={handleSubmit}
                bindDoSubmit={bindDoSubmit}
                formFields={formFields}
                entity={formData}
            />
            <BaseModalFooter>
                <Button filled={true} loading={isSubmitting} type={ButtonType.submit} onClick={clickButton}>
                    {__translate('insertPost.embed')}
                </Button>
            </BaseModalFooter>
        </BaseModal>
    );
};
