import { memo, MemoExoticComponent, ReactElement, SVGAttributes } from 'react';
import dynamic from 'next/dynamic';

import LogoTwitter from '@common/logos/twitter-logo.svg';

import styles from './Logo.module.scss';

const Logo18plus = dynamic(() => import('@common/logos/18-plus.svg'));
const Logo18plusInline = dynamic(() => import('@common/logos/18-plus-inline.svg'));
const Logo18PlusTransparent = dynamic(() => import('@common/logos/18-plus-transparent.svg'));
const Logo18PlusTransparentInline = dynamic(() => import('@common/logos/18-plus-transparent-inline.svg'));
const LogoEmail = dynamic(() => import('@common/logos/email.svg'));
const LogoFacebook = dynamic(() => import('@common/logos/facebook.svg'));
const LogoInstagram = dynamic(() => import('@common/logos/instagram.svg'));
const LogoKva = dynamic(() => import('@common/logos/kva-logo.svg'));
const LogoLink = dynamic(() => import('@common/logos/link-share.svg'));
const LogoLinkedin = dynamic(() => import('@common/logos/linkedIn.svg'));
const LogoMyPxr = dynamic(() => import('@common/logos/my-pxr-logo.svg'));
const LogoPro = dynamic(() => import('@common/logos/pro.svg'));
const LogoProInverse = dynamic(() => import('@common/logos/pro-inverse.svg'));
const LogoRss = dynamic(() => import('@common/logos/rss.svg'));
const LogoSecureBetting = dynamic(() => import('@common/logos/info-secure-betting.svg'));
const LogoTikTok = dynamic(() => import('@common/logos/tiktok.svg'));
const LogoUnibet = dynamic(() => import('@common/logos/unibet-logo.svg'));
const LogoUnibetDark = dynamic(() => import('@common/logos/unibet-logo-dark.svg'));
const LogoWhatsapp = dynamic(() => import('@common/logos/whatsapp.svg'));
const LogoYoutube = dynamic(() => import('@common/logos/youtube.svg'));
const LogoX = dynamic(() => import('@common/logos/x.svg'));

export enum Option {
    eighteenPlus = 'eighteenPlus',
    eighteenPlusInline = 'eighteenPlusInline',
    eighteenPlusTransparent = 'eighteenPlusTransparent',
    eighteenPlusTransparentInline = 'eighteenPlusTransparentInline',
    email = 'email',
    facebook = 'facebook',
    instagram = 'instagram',
    link = 'link',
    linkedIn = 'linkedIn',
    myPxr = 'myPxr',
    pro = 'pro',
    proInverse = 'proInverse',
    rss = 'rss',
    secureBetting = 'secureBetting',
    tikTok = 'tikTok',
    twitter = 'twitter',
    unibet = 'unibet',
    unibetDark = 'unibetDark',
    whatsapp = 'whatsapp',
    youtube = 'youtube',
    kva = 'kva',
    x = 'x',
}

const LogoByOption = (option: Option): typeof LogoTwitter | null => {
    switch (option) {
        case Option.eighteenPlus:
            return Logo18plus;
        case Option.eighteenPlusInline:
            return Logo18plusInline;
        case Option.eighteenPlusTransparent:
            return Logo18PlusTransparent;
        case Option.eighteenPlusTransparentInline:
            return Logo18PlusTransparentInline;
        case Option.email:
            return LogoEmail;
        case Option.facebook:
            return LogoFacebook;
        case Option.instagram:
            return LogoInstagram;
        case Option.linkedIn:
            return LogoLinkedin;
        case Option.myPxr:
            return LogoMyPxr;
        case Option.link:
            return LogoLink;
        case Option.pro:
            return LogoPro;
        case Option.proInverse:
            return LogoProInverse;
        case Option.rss:
            return LogoRss;
        case Option.secureBetting:
            return LogoSecureBetting;
        case Option.tikTok:
            return LogoTikTok;
        case Option.twitter:
            return LogoTwitter;
        case Option.unibet:
            return LogoUnibet;
        case Option.unibetDark:
            return LogoUnibetDark;
        case Option.whatsapp:
            return LogoWhatsapp;
        case Option.youtube:
            return LogoYoutube;
        case Option.kva:
            return LogoKva;
        case Option.x:
            return LogoX;
    }
};

const getIsSquare = (option: Option): boolean => {
    switch (option) {
        case Option.email:
        case Option.facebook:
        case Option.instagram:
        case Option.link:
        case Option.linkedIn:
        case Option.rss:
        case Option.tikTok:
        case Option.twitter:
        case Option.whatsapp:
        case Option.youtube:
        case Option.x:
            return true;
        default:
            return false;
    }
};

interface Props {
    onClick?: () => void;
    svgProps?: React.SVGProps<SVGSVGElement>;
}

type Element = MemoExoticComponent<(props: Props) => JSX.Element> | ((props: Props) => JSX.Element);
type Type = Record<Option, Element>;

// FIXME: Dirty fix to make memo work in storybook
declare global {
    interface Window {
        __STORYBOOK_PREVIEW__: any;
    }
}
const isStoryBook = typeof window === 'object' && window?.__STORYBOOK_PREVIEW__;

const LogoElement = (type: Option) => {
    return ({ svgProps, ...rest }: Props): ReactElement => {
        const classes = ['Logo', styles.Logo, Option[type] + '-logo'];

        const _svgProps: SVGAttributes<SVGSVGElement> = {
            width: '100%',
            height: '100%',
            ...svgProps,
        };

        const isSquare = getIsSquare(type);
        if (isSquare) {
            classes.push(styles.square);
            _svgProps.viewBox = '0 0 24 24';
            _svgProps.preserveAspectRatio = 'xMidYMid meet';
        }

        const _props = {
            ...rest,
            className: classes.join(' '),
            style: {
                cursor: rest.onClick ? 'pointer' : '',
                width: svgProps?.width,
                height: svgProps?.height,
            },
        };

        const ChosenLogo = LogoByOption(type);
        if (ChosenLogo) {
            // FIXME: webpack couldn't properly import style from common folder
            return (
                <span {..._props}>
                    <ChosenLogo {..._svgProps} />
                </span>
            );
        } else {
            return <span {..._props}>Logo.{type} is not yet implemented</span>;
        }
    };
};

export const Logo: Type = ((): Type => {
    const options: Partial<Type> = {};
    Object.values(Option).forEach((key) => {
        options[key] = isStoryBook ? LogoElement(key) : memo(LogoElement(key));
    });
    return options as Type;
})();
