import { useEffect } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { COMMAND_PRIORITY_LOW, CommandListener, CommandListenerPriority, LexicalCommand } from 'lexical';

export const useCommand = <T extends unknown>(
    command: LexicalCommand<T>,
    callback: CommandListener<T>,
    priority: CommandListenerPriority = COMMAND_PRIORITY_LOW,
) => {
    const [editor] = useLexicalComposerContext();
    useEffect(
        () => editor.registerCommand(command, callback, priority),
        [editor, callback, command, priority],
    );
};
