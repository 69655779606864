import { useCallback, useEffect, useRef, useState } from 'react';
import * as React from 'react';
import Script from 'next/script';

import styles from './XPost.module.scss';

const WIDGET_SCRIPT_URL = 'https://platform.twitter.com/widgets.js';

interface Props {
    data: { postID?: string };
    onError?: (error: string) => void;
    onLoad?: () => void;
}

export const XPost = ({ onError, onLoad, data: { postID } }: Props) => {
    const containerRef = useRef<HTMLDivElement | null>(null);

    const previousPostIDRef = useRef<string>('');
    const [isPostLoading, setIsPostLoading] = useState(true);

    const createPost = useCallback(async () => {
        try {
            // @ts-expect-error XPost is attached to the window.
            await window.twttr.widgets.createTweet(postID, containerRef.current);
            setIsPostLoading(false);

            if (onLoad) onLoad();
        } catch (error) {
            if (onError) onError(String(error));
        }
    }, [onError, onLoad, postID]);

    useEffect(() => {
        if (!postID || postID === previousPostIDRef.current) return;

        setIsPostLoading(true);

        if (previousPostIDRef) {
            previousPostIDRef.current = postID;
        }

        createPost();
    });

    return (
        <div className={styles.XPost}>
            {isPostLoading ? <div>Loading</div> : null}
            <Script src={WIDGET_SCRIPT_URL} async={true} onLoad={createPost} onError={onError}></Script>
            <div className={styles.frame} ref={containerRef} />
        </div>
    );
};
