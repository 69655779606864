import { Match, MatchService } from '@common/clients/api';
import { ApiService } from '@common/clients/request';
import { ContextData } from '@common/defaults';

export const getMatches = (contextData: ContextData, search: string): Promise<Match[] | undefined> =>
    ApiService<MatchService>({
        contextData,
        isClientSide: true,
        service: MatchService,
    })
        .searchMatch({ search })
        .then(({ data }) => data);
