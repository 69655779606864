import React, { CSSProperties, useContext, useRef } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { LexicalCommand } from 'lexical';

import { Dropdown, DropdownContext, InlineButton } from '@admin/molecules/RichEditor/Editor/atoms';
import { CustomNodeTypes } from '@admin/molecules/RichEditor/Editor/plugins/CustomPluginNode';
import {
    EmbedIframeModal,
    IframeNodeData,
    INSERT_IFRAME_COMMAND,
} from '@admin/molecules/RichEditor/Editor/plugins/IframePlugin';
import {
    Button,
    ButtonWithModal,
    InsertIframe,
    InsertJobsOverview,
    InsertMatchBlock,
    InsertMatchDetailSection,
    InsertPost,
} from '@admin/molecules/RichEditor/Editor/plugins/InlineToolbarPlugin/atoms';
import {
    INSERT_JOBS_OVERVIEW_COMMAND,
    JobsOverviewNodeData,
} from '@admin/molecules/RichEditor/Editor/plugins/JobsOverviewPlugin';
import {
    INSERT_MATCH_BLOCK_COMMAND,
    MatchBlockModal,
    MatchBlockNodeData,
} from '@admin/molecules/RichEditor/Editor/plugins/MatchBlockPlugin';
import {
    INSERT_MATCH_DETAIL_SECTION_COMMAND,
    MatchDetailSectionModal,
    MatchDetailSectionNodeData,
} from '@admin/molecules/RichEditor/Editor/plugins/MatchDetailSectionPlugin';
import { EmbedPostModal, PostNodeData } from '@admin/molecules/RichEditor/Editor/plugins/XPlugin';
import { BaseModalProvider } from '@admin/organisms/BaseModal';
import { PlatformID } from '@common/clients/api';
import { useContextData } from '@common/useContextData';

import styles from './InlineToolbarPlugin.module.scss';

enum ToolbarOptions {
    insertPost = 'insertPost',
    insertIframe = 'insertIframe',
    insertMatchDetailSection = 'insertMatchDetailSection',
    insertMatchBlock = 'insertMatchBlock',
    insertJobsOverview = 'insertJobsOverview',
}

type LexicalNodeDataTypes =
    | JobsOverviewNodeData
    | IframeNodeData
    | MatchDetailSectionNodeData
    | PostNodeData
    | MatchBlockNodeData;

export const InlineToolbarPlugin = () => {
    const toolbarRef = useRef(null);
    const { isOpen, close, toggle } = useContext(DropdownContext);
    const { platform } = useContextData();
    const [editor] = useLexicalComposerContext();

    const insertNode = (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
        command: LexicalCommand<LexicalNodeDataTypes>,
        data: LexicalNodeDataTypes,
    ) => {
        e?.preventDefault();
        editor.dispatchCommand(command, data);
        close();
    };

    const toolbarOptions = {
        [ToolbarOptions.insertPost]: (
            <div className={styles.item}>
                <ButtonWithModal modal={CustomNodeTypes.post}>
                    <InsertPost />
                </ButtonWithModal>
            </div>
        ),
        [ToolbarOptions.insertIframe]: (
            <div className={styles.item}>
                <ButtonWithModal modal={CustomNodeTypes.iframe}>
                    <InsertIframe />
                </ButtonWithModal>
            </div>
        ),
        [ToolbarOptions.insertMatchDetailSection]: (
            <div className={styles.item}>
                <ButtonWithModal modal={CustomNodeTypes.matchDetailSection}>
                    <InsertMatchDetailSection />
                </ButtonWithModal>
            </div>
        ),
        [ToolbarOptions.insertMatchBlock]: (
            <div className={styles.item}>
                <ButtonWithModal modal={CustomNodeTypes.matchBlock}>
                    <InsertMatchBlock />
                </ButtonWithModal>
            </div>
        ),
        [ToolbarOptions.insertJobsOverview]: (
            <div className={styles.item}>
                <Button
                    onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
                        insertNode(e, INSERT_JOBS_OVERVIEW_COMMAND, { type: CustomNodeTypes.jobsOverview })
                    }
                >
                    <InsertJobsOverview />
                </Button>
            </div>
        ),
    };

    const platformToolbarOptions = [PlatformID.BR, PlatformID.GP].includes(platform.id)
        ? Object.values(ToolbarOptions).filter((option) => option !== ToolbarOptions.insertMatchDetailSection)
        : Object.values(ToolbarOptions);

    const style = { '--dropdownItems': platformToolbarOptions.length } as CSSProperties;

    return (
        <BaseModalProvider>
            <div className={styles.InlineToolbarPlugin} ref={toolbarRef}>
                <div data-theme={platform.id}>
                    <InlineButton onClick={toggle} className={isOpen ? styles.opened : styles.closed}>
                        <svg
                            data-testid="inline-toolbar-plugin"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M16 9.6H9.6V16H6.4V9.6H0V6.4H6.4V0H9.6V6.4H16V9.6Z"
                                fill="currentColor"
                            />
                        </svg>
                    </InlineButton>
                </div>
                <div className={styles.position} style={style}>
                    <Dropdown className={styles.dropDown}>
                        <div className={styles.container}>
                            {platformToolbarOptions.map((option, index) => (
                                <div key={index}>{toolbarOptions[option]}</div>
                            ))}
                        </div>
                    </Dropdown>
                </div>
            </div>
            <MatchDetailSectionModal
                onSubmit={(data) => insertNode(null, INSERT_MATCH_DETAIL_SECTION_COMMAND, data)}
            />
            <EmbedPostModal />
            <MatchBlockModal onSubmit={(data) => insertNode(null, INSERT_MATCH_BLOCK_COMMAND, data)} />
            <EmbedIframeModal
                onSubmit={(data) => {
                    editor.dispatchCommand(INSERT_IFRAME_COMMAND as LexicalCommand<IframeNodeData>, data);
                }}
            />
        </BaseModalProvider>
    );
};
