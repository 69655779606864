import useTranslation from 'next-translate/useTranslation';

import { TranslationKey } from '@admin/utils/TranslationKey';
import { Icon } from '@common/atoms/Icon';

export const InsertJobsOverview = () => {
    const __t = useTranslation(TranslationKey.richEditor).t;

    return (
        <>
            <Icon.jobsOverview
                svgProps={{
                    width: 32,
                    height: 32,
                    viewBox: '0 0 32 32',
                }}
            />
            <span>{__t('insertJobsOverview.button')}</span>
        </>
    );
};
