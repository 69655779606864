import { FC } from 'react';
import useTranslation from 'next-translate/useTranslation';

import { DateTimeUtil, Format } from '@common/utils/DateTimeUtil';

interface Props {
    kickOff?: string;
    homeTeamName?: string;
    awayTeamName?: string;
    className?: string;
}

export const TeamVsTeam: FC<Props> = ({ kickOff, homeTeamName, awayTeamName, className = '' }: Props) => {
    const __translate = useTranslation('common').t;

    return (
        <p className={className}>
            <span> {DateTimeUtil.format(kickOff, Format.DATE_NUMERIC)} </span>
            <span>
                {homeTeamName}
                <b> {__translate`vs`} </b>
                {awayTeamName}
            </span>
        </p>
    );
};
