import { useEffect, useState } from 'react';

import { TeamVsTeam } from '@admin/atoms/TeamVsTeam';
import { ArticleBlock } from '@admin/molecules/RichEditor/Editor/atoms/ArticleBlock';
import { getMatch } from '@admin/molecules/RichEditor/Editor/helpers';
import { CustomNodeTypes } from '@admin/molecules/RichEditor/Editor/plugins/CustomPluginNode';
import { ModalActions, useModalContext } from '@admin/organisms/BaseModal';
import { Match } from '@common/clients/api';
import { useContextData } from '@common/useContextData';

import { MatchBlockModal, MatchBlockNodeData } from '.';

import styles from './MatchBlockArticleBlock.module.scss';

interface Props extends MatchBlockNodeData {
    onChange: (data: MatchBlockNodeData) => void;
    onDelete: () => void;
}

export const MatchBlockArticleBlock = ({ onChange, onDelete, matchID, type }: Props) => {
    const { openModal } = useModalContext(CustomNodeTypes.matchBlock);
    const contextData = useContextData();
    const [match, setMatch] = useState<Match | null>();

    useEffect(() => {
        getMatch({ contextData, matchID }).then(setMatch);
    }, [matchID, contextData]);

    if (!match) {
        return null;
    }

    return (
        <div data-theme="admin" className={styles.MatchBlockArticleBlock}>
            <ArticleBlock onClick={openModal} onDelete={onDelete} customNodeType={CustomNodeTypes.matchBlock}>
                <TeamVsTeam
                    className={styles.teamVsTeam}
                    kickOff={match.kickOff}
                    awayTeamName={match.away?.name}
                    homeTeamName={match.home?.name}
                />
            </ArticleBlock>
            <MatchBlockModal formData={{ matchID, type }} onSubmit={onChange} action={ModalActions.update} />
        </div>
    );
};
