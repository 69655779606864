import { useContext } from 'react';

import { DropdownContext } from '@admin/molecules/RichEditor/Editor/atoms';
import { BaseModalType, useModalContext } from '@admin/organisms/BaseModal';

import { CustomNodeTypes } from '../../../CustomPluginNode';

import styles from './ButtonWithModal.module.scss';

interface Props {
    modal: BaseModalType | CustomNodeTypes;
    children?: React.ReactNode;
}

export const ButtonWithModal = ({ modal, children }: Props) => {
    const { openModal } = useModalContext(modal || BaseModalType.Default);
    const { close } = useContext(DropdownContext);
    const handleClick = () => {
        openModal();
        close();
    };

    return (
        <button onClick={handleClick} className={styles.ButtonWithModal}>
            {children}
        </button>
    );
};
