import { ArticleBlock } from '@admin/molecules/RichEditor/Editor/atoms/ArticleBlock';
import { CustomNodeTypes } from '@admin/molecules/RichEditor/Editor/plugins/CustomPluginNode';
import { ModalActions, useModalContext } from '@admin/organisms/BaseModal';

import { EmbedIframeModal } from './EmbedIframeModal';
import { IframeNodeData } from './IframeNode';

interface Props extends IframeNodeData {
    onChange: (data: IframeNodeData) => void;
    onDelete: () => void;
}

const extractIframeUrl = (iframe: string) => {
    const srcMatch = iframe.match(/src=["']([^"']+)/);
    if (srcMatch) {
        return srcMatch[1];
    }

    const urlMatch = iframe.match(/http[^"']+/);
    if (urlMatch) {
        return urlMatch[0];
    }

    return null;
};

export const IframeArticleBlock = ({ onChange, onDelete, iframe, type }: Props) => {
    const { openModal } = useModalContext(CustomNodeTypes.iframe);

    if (!iframe) {
        return null;
    }

    const iframeUrl = extractIframeUrl(iframe);

    return (
        <div data-theme="admin">
            <ArticleBlock onClick={openModal} onDelete={onDelete} customNodeType={CustomNodeTypes.iframe}>
                <span>{iframeUrl}</span>
            </ArticleBlock>
            <EmbedIframeModal formData={{ iframe, type }} onSubmit={onChange} action={ModalActions.update} />
        </div>
    );
};
