import { useEffect } from 'react';
import { $generateNodesFromDOM } from '@lexical/html';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $getRoot, LexicalEditor } from 'lexical';

interface Props {
    value?: string;
}

export const DefaultValuePlugin = ({ value }: Props) => {
    const [editor] = useLexicalComposerContext();

    const updateHTML = (innerEditor: LexicalEditor, value: string) => {
        const root = $getRoot();
        const parser = new DOMParser();
        const dom = parser.parseFromString(value, 'text/html');

        const nodes = $generateNodesFromDOM(innerEditor, dom);

        root.clear();
        root.append(...nodes);
    };

    useEffect(() => {
        if (editor && value) {
            editor.update(() => {
                updateHTML(editor, value);
            });
        }
    }, [editor, value]);

    return null;
};
