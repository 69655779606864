import { ArticleBlock } from '@admin/molecules/RichEditor/Editor/atoms/ArticleBlock';
import { CustomNodeTypes } from '@admin/molecules/RichEditor/Editor/plugins/CustomPluginNode';

import { JobsOverviewNodeData } from './JobsOverviewNode';

interface Props extends JobsOverviewNodeData {
    onDelete: () => void;
}

export const JobsOverviewArticleBlock = ({ onDelete }: Props) => {
    return (
        <div data-theme="admin" data-testId="jobs-overview-article-block">
            <ArticleBlock onDelete={onDelete} customNodeType={CustomNodeTypes.jobsOverview} />
        </div>
    );
};
