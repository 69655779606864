export enum SHORTEN {
    NONE = 0,
    LONG = 7,
    MEDIUM = 5,
    SMALL = 3,
}

export enum FORMAT {
    COMMA_DOT = '#,###.00',
    DOT_COMMA = '#.###,00',
    SPACE_COMMA = '# ###,00',
}

export const abbr: { [key: number]: string } = {
    3: 'k',
    6: 'M',
    9: 'Bn',
    12: 'Tr',
};

export const OPTIONS: { [key: string]: string } = {
    [FORMAT.COMMA_DOT]: '1,234.56',
    [FORMAT.DOT_COMMA]: '1.234,56',
    [FORMAT.SPACE_COMMA]: '1 234,56',
};
export const number_format = (
    number: number,
    decimals: number = 0,
    decimalSeparator: string = '.',
    thousandSeparator: string = ',',
): string => {
    const fixedNumber = number.toFixed(decimals);
    const parts = fixedNumber.split('.');
    const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator);
    const formattedNumber = decimals > 0 ? integerPart + decimalSeparator + parts[1] : integerPart;

    return formattedNumber;
};

export const findAbbreviation = (length: number, shorten: number) => {
    for (const [abbrLengthStr, abbrValue] of Object.entries(abbr)) {
        const abbrLength = Number(abbrLengthStr);
        if (length - shorten >= abbrLength) continue;
        return [abbrLengthStr, abbrValue];
    }

    return [3, ''];
};

export const formatNumber = (
    amount: number = 0,
    shorten: SHORTEN = SHORTEN.MEDIUM,
    minDecimals: number = 0,
    maxDecimals: number = 2,
): string => {
    const numberFormat = FORMAT.DOT_COMMA;
    const thousandSeparator = numberFormat[1];
    const decimalSeparator = numberFormat[5];
    const isNegative = amount < 0;
    amount = Math.abs(amount);

    if (amount >= 100 && shorten !== SHORTEN.NONE) {
        let length = Math.ceil(Math.log10(amount + 1)); // Adding 1 to handle edge cases with log10(1000) etc.

        if (length > shorten) {
            // Give values like 149xx a bit more space than 560xx
            if (shorten === SHORTEN.SMALL && amount / Math.pow(10, length - 1) < 5) length--;

            const abbrValue = findAbbreviation(length, shorten) ?? '';

            const lengthDiff = length - Number(abbrValue[0]);
            const allowDecimal = shorten !== SHORTEN.LONG && lengthDiff !== 3 && lengthDiff + 1 < shorten;

            if (allowDecimal) {
                let small = Math.round(amount / Math.pow(10, Number(abbrValue[0]) - 1)) / 10;
                if (small < 0.5) small = Math.round(amount / Math.pow(10, Number(abbrValue[0]) - 2)) / 100;
                amount = small;
            } else {
                amount = Math.round(amount / Math.pow(10, Number(abbrValue[0])));
            }

            return formatNumber(amount, SHORTEN.NONE, 0, allowDecimal ? 2 : 0) + abbrValue[1];
        }
    }

    let result = number_format(amount, maxDecimals, decimalSeparator, thousandSeparator);
    const trailingZeros = maxDecimals - minDecimals;
    if (trailingZeros > 0) {
        result = result.replace(
            new RegExp(`(\\${decimalSeparator}0{${trailingZeros}})|(0{1,${trailingZeros}})$`),
            '',
        );
    } else if (maxDecimals > 0) {
        result = result.replace(new RegExp(`(\\${decimalSeparator}0{${maxDecimals}})$`), '');
    }

    result = result.replace(/ /g, '&nbsp;');
    result = isNegative ? '-' + result : result;
    return result;
};
