import styles from './Button.module.scss';

interface Props {
    onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    children?: React.ReactNode;
}

export const Button = ({ onClick, children }: Props) => {
    return (
        <button onClick={onClick} className={styles.Button}>
            {children}
        </button>
    );
};
